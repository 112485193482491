<template>
  <div class="page-main">
      <TopNav/>
      <router-view :key="$route.path"/>
      <Footer/>
  </div>     
</template>

<script>

export default {
  name: 'Cockpit',
  components: {
    TopNav: () => import('@/components/TopNav'),
    Footer: () => import('@/components/Footer')
  },
  created () { 

  },
  beforeDestroy () {

  }
}
</script>

<style>
.page-main{
  background: #f5f5f5;
}
</style>



